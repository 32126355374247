import React, { useState, useEffect } from "react";

import axios from "axios";

import { StyleSheet, css } from "aphrodite";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import LogoutIcon from "@mui/icons-material/LogoutOutlined";

import Divider from "@mui/material/Divider";

import logoIvitech_colorido from "../../assets/imgs/logoivitech_colorido.png";
import ivitech_reduced from "../../assets/imgs/ivitech_reduced.png";

import "../../assets/styles/drawer.css";

import { headers } from "../core/store/localStorage";
import errorHandler from "../core/store/errorHandler";

// const API_URL = localStorage.getItem("apiUrl");
// const userId = localStorage.getItem("userId");

const asyncLocalStorage = {
  async getItem(key: any) {
    await null;
    return localStorage.getItem(key);
  },
};

interface Props {
  open: boolean;
  openDialog: boolean;
  openQuerySubMenu: boolean;
  toggleDialog: () => void;
  toggleQueryMenu: () => void;
}

const DrawerItemsList: any = (props: Props) => {
  const [pages, setPages] = useState<any>("");
  const [API_URL, setCurrentAPI] = useState<any>("");
  const [userId, setCurrentUserID] = useState<any>("");

  useEffect(() => {
    getAPI();
    getUserID();
  }, []);

  useEffect(() => {
    getUserAccess();
  }, [API_URL, userId]);

  // console.log(API_URL);
  // console.log(userId);

  const { open, openDialog, openQuerySubMenu, toggleDialog, toggleQueryMenu } =
    props;

  const [openVendasSubMenu, setOpenVendasSubMenu] = useState(false);

  function toggleVendasMenu() {
    setOpenVendasSubMenu(!openVendasSubMenu);
  }

  function getAPI() {
    asyncLocalStorage.getItem("apiUrl").then((response) => {
      setCurrentAPI(response);
    });
  }
  function getUserID() {
    asyncLocalStorage.getItem("userId").then((response) => {
      setCurrentUserID(response);
    });
  }

  async function getUserAccess() {
    await axios
      .get(`${API_URL}/app/user/${userId}/tela`, headers())
      .then((response) => {
        let resp: any = [];
        response.data.data.map((res: any) => {
          resp.push(res.name);
        });
        setPages(resp);
      })
      .catch((err) => {
        errorHandler(err);
      });
  }

  function clearData() {
    localStorage.clear();
  }

  return (
    <>
      <List style={{ marginTop: "-0.5rem" }}>
        {pages.includes("/lista-usuarios") ? (
          <Link to={"/lista-usuarios"} className={css(styles.noDecoration)}>
            <ListItemButton
              // selected={!!useMatch("/produtos/*")}
              key={"Lista de Usuários"}
              sx={{
                minHeight: 15,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 1.4 : "auto",
                  ml: open ? "" : -0.2,
                  justifyContent: "center",
                }}
                title={open ? "" : "Lista de Usuários"}
              >
                <PeopleAltOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Lista de Usuários"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </Link>
        ) : (
          ""
        )}

        {pages.includes("/alterar-senha") ? (
          <Link to={"/alterar-senha"} className={css(styles.noDecoration)}>
            <ListItemButton
              // selected={!!useMatch("/produtos/*")}
              key={"Alterar Senha"}
              sx={{
                minHeight: 15,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 1.4 : "auto",
                  ml: open ? "" : -0.2,
                  justifyContent: "center",
                }}
                title={open ? "" : "Alterar Senha"}
              >
                <PasswordOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Alterar Senha"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </Link>
        ) : (
          ""
        )}

        <Divider />
        <Link to={"/"} className={css(styles.noDecoration)}>
          <ListItemButton
            key={"Sair"}
            sx={{
              minHeight: 15,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={clearData}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 1.4 : "auto",
                ml: open ? 0.2 : "",
                justifyContent: "center",
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={"Sair"} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </Link>
      </List>

      <div>
        <Link to={"https://ivitech.com.br"} target="_blank">
          <img
            src={open ? logoIvitech_colorido : ivitech_reduced}
            style={{
              position: "absolute",
              bottom: 20,
              left: open ? "27%" : "15%",
              width: open ? "6.5rem" : "2.5rem",
            }}
          />
        </Link>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  noDecoration: {
    textDecoration: "none",
    color: "inherit",
  },
});

export default DrawerItemsList;
