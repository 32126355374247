// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getRemoteConfig,
  fetchAndActivate,
  getValue,
} from "firebase/remote-config";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDztDFwa6YraqgQdLVV3MPYMi8VCHzNfLk",
  authDomain: "xsped-web.firebaseapp.com",
  projectId: "xsped-web",
  storageBucket: "xsped-web.appspot.com",
  messagingSenderId: "348693999509",
  appId: "1:348693999509:web:c712add076a568a8d6909c",
  measurementId: "G-0QEX97P9YE",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.defaultConfig = {
  server_url_test: "aplicacao.ivitech.com.br/hxsped",
};

export const storage = getStorage(app);

export const activateFirebaseConfig = async (
  suffix: string
): Promise<string> => {
  return new Promise<string>(async (resolve, reject) => {
    try {
      await fetchAndActivate(remoteConfig);

      if (suffix === "dev") {
        const value = getValue(remoteConfig, "server_url_dev");
        resolve(`https://${value.asString()}/api/v1`);
      } else if (suffix === "teste") {
        const value = getValue(remoteConfig, "server_url_test");
        resolve(`https://${value.asString()}/api/v1`);
      } else if (suffix === "local") {
        resolve("http://192.168.3.116:8080/dxsped/api/v1");
      } else {
        const value = getValue(remoteConfig, "server_url_prod");
        resolve(`https://${value.asString()}/api/v1`);
      }
    } catch (err) {
      //console.log(err);
      reject({ message: "Network Error" });
    }
  });
};
