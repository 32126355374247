import React from "react";
import { useEffect } from "react";
import {
  Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useMatch,
} from "react-router-dom";

import MainLayout from "./components/layout/MainLayout";
import LoginPage from "./pages/login/Login";
import MainPage from "./pages/main/mainPage";
import ListaUsuarios from "./pages/users/ListaUsuarios";
import NovoUsuario from "./pages/users/NovoUsuario";
import AlterarSenha from "./pages/users/AlterarSenha";

import CadastraSenha from "./pages/settings/CadastraSenha";
import RedefinirSenha from "./pages/settings/RedefinirSenha";
import RecuperarSenha from "./pages/settings/RecuperaSenha";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBR from "date-fns/locale/pt-BR";

function App() {
  const location = useLocation();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/recuperar-senha" element={<RecuperarSenha />} />
        <Route path="/user/cadastrar-senha" element={<CadastraSenha />} />
        <Route path="/user/redefinir-senha" element={<RedefinirSenha />} />
        <Route element={<MainLayout />}>
          <Route path="/main" element={<MainPage />} />
          <Route path="/lista-usuarios" element={<ListaUsuarios />} />
          <Route path="/novo-usuario" element={<NovoUsuario />} />
          <Route path="/alterar-senha" element={<AlterarSenha />} />
        </Route>
      </Routes>
    </LocalizationProvider>
  );
}

export default App;
