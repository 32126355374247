import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import {
  TextField,
  Button,
  Stack,
  Card,
  CardContent,
  Box,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@mui/material";

import { CAMPO_OBRIGATORIO } from "../../util/string/utilStrings";

import axios from "axios";

import Colors from "../../components/constants/Colors";
import bg_image from "../../assets/imgs/bg.png";
import logoivitech from "../../assets/imgs/logoivitech.png";
import xsped_title from "../../assets/imgs/XSPED_title.png";

import { StyleSheet, css } from "aphrodite";

import { headers } from "../../components/core/store/localStorage";
import errorHandler from "../../components/core/store/errorHandler";

// const API_URL = "http://192.168.3.116:8080/dxsped/api/v1";
const API_URL = "https://aplicacao.ivitech.com.br/xsped/api/v1";

function RecuperarSenha() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);
  const [critica, setCritica] = useState(false);
  const [erro, setErro] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sucesso, setSucesso] = useState(false);

  const successMessage =
    "Você receberá instruções para recuperar sua senha no e-mail registrado.";

  const handleEnterPress = (ev) => {
    if (ev.key === "Enter") {
      sendRequest();
    }
  };

  function sendRequest() {
    setSucesso(false);
    setCritica(true);
    setLoading(true);

    if (email.length === 0) {
      setLoading(false);
    } else {
      axios
        .post(`${API_URL}/app/user/password/requestset?email=${email}`)
        .then((response) => {
          setCritica(false);
          setLoading(false);
          setSucesso(true);
        })
        .catch((err) => {
          setCritica(false);
          setLoading(false);

          console.log(err);
          setErrorMessage(
            "Houve um erro na requisição. Tente novamente mais tarde."
          );
          setErro(true);
        });
    }
  }

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          backgroundImage: `url(${bg_image})`,
          backgroundSize: "cover",
          left: 0,
          top: 0,
          minHeight: "100%",
          minWidth: "100%",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Box
          mt={7}
          mb={1.5}
          style={{ marginTop: "5%", width: "14%" }}
          component={"img"}
          src={xsped_title}
          alt={"XSPED"}
        ></Box>
        <Card sx={{ width: { md: "24%", sm: "30%", xs: "50%" }, mb: 2 }}>
          <CardContent>
            <Stack spacing={2} width="100%" alignItems={"center"}>
              <p className={css(styles.title)}>Recuperar Senha</p>
              <TextField
                onKeyPress={handleEnterPress}
                fullWidth
                error={critica && email.length === 0}
                value={email}
                type={"text"}
                onChange={(ev) => setEmail(ev.target.value)}
                size={"small"}
                color={"primary"}
                label="E-mail do usuário"
                helperText={email.length === 0 ? CAMPO_OBRIGATORIO : ""}
              />

              <Button
                onClick={sendRequest}
                disabled={loading}
                sx={{
                  backgroundColor: Colors.primary,
                  fontFamily: "Poppins",
                  color: "white",
                  fontWeight: 600,
                  letterSpacing: 1.5,
                  borderColor: Colors.primary,
                  "&:hover": {
                    backgroundColor: "blue",
                    color: "white",
                    borderColor: "blue",
                  },
                  "&:disabled": {
                    backgroundColor: "blue",
                    color: "white",
                    borderColor: "blue",
                  },
                }}
                fullWidth
                variant="outlined"
              >
                {loading ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Confirmar"
                )}
              </Button>

              <button
                className={loading ? css(styles.disabled) : css(styles.link)}
                disabled={loading ? true : false}
                onClick={() => navigate("/")}
              >
                VOLTAR
              </button>
            </Stack>
          </CardContent>
        </Card>
        {erro && (
          <Alert
            severity="error"
            sx={{ width: "50%", zIndex: 2 }}
            closeText={"Fechar mensagem"}
            onClose={() => setErro(false)}
          >
            <AlertTitle>Erro</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        {sucesso && (
          <Alert
            severity="success"
            sx={{ width: "50%", zIndex: 2 }}
            closeText={"Fechar mensagem"}
            onClose={() => navigate("/")}
          >
            <AlertTitle>Requisição enviada</AlertTitle>
            {successMessage}
          </Alert>
        )}
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            textAlign: "center",
          }}
        >
          <Box
            mt={7}
            mb={1.5}
            component={"img"}
            src={logoivitech}
            alt={"Ivitech Tecnologia"}
          />
          <p className={css(styles.lgpd)}>
            Adequada à Lei Geral de Proteção de Dados.
          </p>
        </div>
      </Box>
    </>
  );
}

const styles = StyleSheet.create({
  title: {
    color: "#4b4b4b",
    fontFamily: "Poppins",
    marginTop: "-0.15rem",
    marginBottom: "-0.20rem",
    fontSize: 14,
    fontWeight: 600,
  },
  titleAlt: {
    color: "green",
    fontFamily: "Poppins",
    marginTop: "-0.15rem",
    marginBottom: "-0.20rem",
    fontSize: 14,
    fontWeight: 600,
  },
  text: {
    color: "#4b4b4b",
    fontFamily: "Poppins",
    fontSize: 13,
    fontWeight: 500,
  },
  lgpd: {
    color: "#fff",
    fontFamily: "Poppins",
    marginTop: "-0.15rem",
    fontSize: 13,
  },
  input: {
    fontSize: 12,
    // fontFamily: "Poppins",
  },
  link: {
    backgroundColor: "transparent",
    border: "none",
    color: "#6163B0",
    fontSize: 13,
    fontFamily: "Poppins",
    fontWeight: 600,
    letterSpacing: 2,
    textDecoration: "underline",
    cursor: "pointer",

    ":active": {
      color: "red",
    },
  },
  disabled: {
    backgroundColor: "transparent",
    border: "none",
    color: "grey",
    textDecoration: "none",
    fontSize: 13,
    fontFamily: "Poppins",
    fontWeight: 600,
    letterSpacing: 2,
  },
});

export default RecuperarSenha;
