import { Button } from "reactstrap";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

import "../../assets/styles/botoes.css";

const BotaoVoltar = (props) => {
  return (
    <>
      <Button
        id="idVoltar"
        className="btn-sm botao-secundario"
        style={{
          display: "flex",
          alignItems: "center",
          padding: "0.3rem 0.6rem 0.3rem 0.4rem",
          cursor: "pointer",
        }}
      >
        <ArrowBackOutlinedIcon style={{ width: 16, marginRight: 2 }} />
        <span style={{ fontSize: 12 }}>Voltar</span>
      </Button>
    </>
  );
};

export default BotaoVoltar;
