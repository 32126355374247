import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";

import { StyleSheet, css } from "aphrodite";
import logoXsped from "../../assets/imgs/XSPED_title.png";

const API_URL = localStorage.getItem("apiUrl");
const userId = localStorage.getItem("userId");

const asyncLocalStorage = {
  async getItem(key: any) {
    await null;
    return localStorage.getItem(key);
  },
};

const MainPage = (): JSX.Element => {
  const [API_URL, setCurrentAPI] = useState<any>("");
  const [userId, setCurrentUserID] = useState<any>("");

  useEffect(() => {
    getAPI();
    getUserID();
  }, []);

  console.log(API_URL);
  console.log(userId);

  function getAPI() {
    asyncLocalStorage.getItem("apiUrl").then((response) => {
      setCurrentAPI(response);
    });
  }

  function getUserID() {
    asyncLocalStorage.getItem("userId").then((response) => {
      setCurrentUserID(response);
    });
  }

  if (API_URL === null || userId === null) {
    window.location.reload();
  }

  return (
    <>
      <Box
        width={"100%"}
        height={"80vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {/* Bloco de exibição da logomarca da empresa */}
        {/* <p className={css(styles.templateText)}>(Logo da empresa aqui)</p> */}
        <Box
          component={"img"}
          src={logoXsped}
          alt={"Logo XSPED"}
          width={{ xs: "90%", sm: "50%", md: "30%" }}
        />
      </Box>
    </>
  );
};

const styles = StyleSheet.create({
  templateText: {
    color: "#4b4b4b",
    fontFamily: "Poppins",
    fontSize: 20,
    fontWeight: 600,
  },
});

export default MainPage;
