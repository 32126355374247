import React, { useEffect, useRef, useState } from "react";
import Filter from "../../components/filters/Filter";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";

import AtivaModal from "../../components/modals/AtivaModal";
import DesativaModal from "../../components/modals/DesativaModal";

import { Box, Grid, Pagination, PaginationItem } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import axios from "axios";
import { Link, useLocation } from "react-router-dom";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import { StyleSheet, css } from "aphrodite";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../assets/styles/tabela.css";
import Colors from "../../components/constants/Colors";

import { headers } from "../../components/core/store/localStorage";
import errorHandler from "../../components/core/store/errorHandler";
const API_URL = localStorage.getItem("apiUrl");

function ListaUsuarios() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1");
  const filter = query.get("filtro");
  const [pagination, setPagination] = useState("");

  const dt = new Date();
  const pgTitle = "Lista de Usuários";

  const isFirstRender = useRef(true);

  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [erro, setErro] = useState("");
  const [trigger, setTrigger] = useState(false);

  const [inicio, setInicio] = useState(dt);
  const [fim, setFim] = useState(dt);

  const [ativaOpen, setAtivaOpen] = useState(false);
  const [desativaOpen, setDesativaOpen] = useState(false);

  let pg = 1;
  let status;

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    // setPg(page);
    pg = page;

    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    getUsers();
  }, [page]);

  function getUsers() {
    let dayIni = inicio.getDate();
    let dayFim = fim.getDate();

    let monthIni = inicio.getMonth() + 1;
    let monthFim = fim.getMonth() + 1;

    let formatedIni =
      inicio.getFullYear() +
      "-" +
      (monthIni < 10 ? `0${monthIni}` : monthIni) +
      "-" +
      (dayIni < 10 ? `0${dayIni}` : dayIni);

    let formatedFim =
      fim.getFullYear() +
      "-" +
      (monthFim < 10 ? `0${monthFim}` : monthFim) +
      "-" +
      (dayFim < 10 ? `0${dayFim}` : dayFim);

    console.log(formatedIni);
    console.log(formatedFim);

    if (!API_URL) {
      window.location.reload();
      setLoading(true);

      axios
        .get(
          `${API_URL}/app/user/all?page=${pg}&inclusaoInicial='${formatedIni}'&inclusaoFinal='${formatedFim}'`,
          headers()
        )
        .then((response) => {
          setLoading(false);
          let resp = [];
          response.data.data.map((res) => {
            resp.push({
              id: res.id,
              nome: res.name,
              email: res.email,
              inclusion: res.inclusion,
              lastAcess: res.lastAcess,
              isBlocked: res.isBlocked,
            });
          });
          setUsers(resp);
          setPagination(response.data.totalPages);
        })
        .catch((err) => {
          setLoading(false);
          errorHandler(err);
        });
    }
    setLoading(true);
    axios
      .get(
        `${API_URL}/app/user/all?page=${pg}&inclusaoInicial='${formatedIni}'&inclusaoFinal='${formatedFim}'`,
        headers()
      )
      .then((response) => {
        setLoading(false);
        let resp = [];
        response.data.data.map((res) => {
          resp.push({
            id: res.id,
            nome: res.name,
            email: res.email,
            inclusion: res.inclusion,
            lastAcess: res.lastAcess,
            isBlocked: res.isBlocked,
          });
        });
        setUsers(resp);
        setPagination(response.data.totalPages);
      })
      .catch((err) => {
        setLoading(false);
        errorHandler(err);
      });
  }

  let qtdeLinhas = users.length;
  if (qtdeLinhas % 2 === 0) {
    qtdeLinhas += 1;
  }

  const startAtiva = (id) => {
    status = id;
    setAtivaOpen(!ativaOpen);
    setUserId(status);
  };
  const startDesativa = (id) => {
    status = id;
    setDesativaOpen(!desativaOpen);
    setUserId(status);
  };

  const closeAtiva = () => {
    setAtivaOpen(false);
    console.log(userId);
  };
  const closeDesativa = () => {
    setDesativaOpen(false);
    console.log(userId);
  };

  const objTeste = "teste";

  function ativaUser() {
    axios
      .post(`${API_URL}/app/user/${userId}/unblock`, objTeste, headers())
      .catch((err) => {
        console.log(err);
      });
    setAtivaOpen(!ativaOpen);
    window.location.reload();
  }

  function desativaUser() {
    axios
      .post(`${API_URL}/app/user/${userId}/block`, objTeste, headers())
      .catch((err) => {
        console.log(err);
      });
    setDesativaOpen(!desativaOpen);
    window.location.reload();
  }

  function updateInicio(e) {
    setInicio(e);
  }
  function updateFim(e) {
    setFim(e);
  }

  const searchUsers = () => {
    if (inicio > fim) {
      setErro(
        "A data final deve ser posterior à data de início. Selecione o período novamente."
      );
    } else {
      setErro("");
      setTrigger(true);
      getUsers();
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container wrap="wrap">
          <Grid
            item
            xs={6}
            sm={7}
            md={8}
            lg={10}
            style={{ marginTop: "-1.6rem" }}
          >
            <h3 className={css(styles.pageTitle)}>{pgTitle}</h3>
          </Grid>

          <Grid
            item
            xs={6}
            sm={5}
            md={4}
            lg={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginTop: "-1.3rem",
            }}
          >
            <Link to={`/novo-usuario`}>
              <button
                //   onClick={}
                className={css(styles.btn)}
                style={{ marginRight: 5, padding: "4px 8px 2.5px 8px" }}
                title="Criar novo usuário"
              >
                <AddOutlinedIcon />
              </button>
            </Link>
          </Grid>
        </Grid>

        <hr
          style={{
            color: "#eee",
            marginTop: "-0.55rem",
            marginBottom: "1rem",
          }}
        />

        <Filter
          iniValue={inicio}
          iniSet={updateInicio}
          fimValue={fim}
          fimSet={updateFim}
          search={searchUsers}
          erro={erro}
          loading={loading}
        />
      </Box>

      {users && users.length > 0 && !loading ? (
        <>
          <Table>
            <Thead className="table_head">
              <Tr>
                <Th className="text-center">Nome</Th>
                <Th className="text-center">E-mail</Th>
                <Th className="text-center">Data de Inclusão</Th>
                <Th className="text-center">Último Acesso</Th>
                <Th className="text-center">Ações</Th>
              </Tr>
            </Thead>

            <Tbody className="table_body">
              {users.map((list) => {
                var parity = qtdeLinhas % 2;
                qtdeLinhas = qtdeLinhas - 1;

                let info = {
                  codigo: list.codigo,
                  nome: list.nome,
                  un: list.unMedida,
                };

                return (
                  <Tr
                    key={`${list.id}`}
                    className={parity === 1 ? "odd_row" : "even_row"}
                  >
                    <Td
                      className={
                        list.isBlocked ? css(styles.blkdUser) : "text-center"
                      }
                    >
                      {list.nome}
                    </Td>
                    <Td
                      className={
                        list.isBlocked ? css(styles.blkdUser) : "text-center"
                      }
                    >
                      {list.email}
                    </Td>
                    <Td
                      className={
                        list.isBlocked ? css(styles.blkdUser) : "text-center"
                      }
                    >
                      {list.inclusion}
                    </Td>
                    <Td
                      className={
                        list.isBlocked ? css(styles.blkdUser) : "text-center"
                      }
                    >
                      {list.lastAcess}
                    </Td>
                    <Td className="text-center" style={{ width: "6%" }}>
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        {!list.isBlocked ? (
                          <button
                            onClick={() => startDesativa(list.id)}
                            className={css(styles.btnAtiva)}
                            title={"Usuário ativo"}
                          >
                            <CheckCircleOutlinedIcon />
                          </button>
                        ) : (
                          <button
                            onClick={() => startAtiva(list.id)}
                            className={css(styles.btnDesativa)}
                            title={"Usuário desativado"}
                          >
                            <BlockOutlinedIcon />
                          </button>
                        )}
                      </span>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>

          {pagination > 1 && (
            <Pagination
              showFirstButton
              showLastButton
              page={page}
              count={pagination}
              sx={{
                "& .MuiPaginationItem-root": {
                  "&.Mui-selected": {
                    background: Colors.mainLayoutAtiveBackground,
                    color: "black",
                  },
                },
                marginTop: "0.5rem",
              }}
              renderItem={(item) => (
                <PaginationItem
                  component={Link}
                  to={`/vendas/meiopagamento${
                    filter
                      ? `?page=${item.page}&filtro=${filter}`
                      : `?page=${item.page}`
                  }`}
                  {...item}
                />
              )}
            />
          )}
        </>
      ) : loading ? (
        <Box
          width={"100%"}
          height={"45vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            item
            xs={12}
            md={12}
            // height={{ md: "20%", xs: "50%" }}
            textAlign={"center"}
            justifyContent={"center"}
            alignItems={"center"}
            bgcolor={"#ECF9F2"}
            borderRadius={5}
            padding={5}
            color={Colors.noResultFont}
          >
            <div
              display={"flex"}
              style={{
                marginTop: "-0.5rem",
              }}
            >
              <CircularProgress color="success" />
            </div>
            <div display={"flex"}>
              <p
                style={{
                  color: "#4b4b4b",
                  fontSize: 14,
                  marginBottom: "-1rem",
                }}
              >
                Buscando informações
              </p>
            </div>
          </Grid>
        </Box>
      ) : (
        <Box
          width={"100%"}
          height={"60vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            item
            xs={12}
            md={12}
            height={{ md: "20%", xs: "50%" }}
            display={"inline-flex"}
            textAlign={"center"}
            justifyContent={"center"}
            alignItems={"center"}
            bgcolor={"lightyellow"}
            borderRadius={5}
            padding={5}
            color={Colors.noResultFont}
          >
            <p
              style={{
                color: "#4b4b4b",
                // fontFamily: "Poppins",
                fontSize: 14,
              }}
            >
              Sua pesquisa não retornou nenhum resultado.
            </p>
          </Grid>
        </Box>
      )}

      <AtivaModal modalOpen={ativaOpen} toggle={ativaUser} close={closeAtiva} />
      <DesativaModal
        modalOpen={desativaOpen}
        toggle={desativaUser}
        close={closeDesativa}
      />
    </>
  );
}

const styles = StyleSheet.create({
  pageTitle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    color: "#4b4b4b",
  },
  blkdUser: {
    backgroundColor: "#FFF9D6",
    color: "#9C9C9C",
    textAlign: "center",
  },
  btn: {
    backgroundColor: "#6163B0",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    textAlign: "center",
    textDecoration: "none",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#6163B0",
    borderRadius: 4,
    marginTop: "-0.5rem",
    cursor: "pointer",

    // padding: "5px 8px 3px 8px",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#6163B0",
      backgroundColor: "transparent !important",
      borderColor: "#6163B0 !important",
    },
    ":active": {
      color: "#6163B0",
      backgroundColor: "transparent !important",
      borderColor: "#6163B0 !important",
      opacity: 0.6,
    },
  },
  btnAtiva: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    height: "1.2rem",
    width: "2rem",
    // paddingTop: "0.25rem",
    paddingBottom: 20,
    backgroundColor: "transparent",
    borderStyle: "none",
    color: "#28a745",
    transition: "0.4s",
    cursor: "pointer",

    ":hover": {
      color: "#4ECE6B",
      backgroundColor: "transparent !important",
    },
    ":active": {
      color: "#4ECE6B",
      backgroundColor: "transparent !important",
      opacity: 0.6,
    },
  },
  btnDesativa: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    height: "1.2rem",
    width: "2rem",
    // paddingTop: "0.25rem",
    paddingBottom: 20,
    backgroundColor: "transparent",
    borderStyle: "none",
    color: "#ffc107",
    transition: "0.4s",
    cursor: "pointer",

    ":hover": {
      color: "#FFD967",
      backgroundColor: "transparent !important",
    },
    ":active": {
      color: "#FFD967",
      backgroundColor: "transparent !important",
      opacity: 0.6,
    },
  },
  btnDisabled: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    height: "1.2rem",
    width: "2rem",
    paddingBottom: 20,
    backgroundColor: "transparent",
    borderStyle: "none",
    color: "#B3B3B3",
  },
});

export default ListaUsuarios;
