import React, { useState } from "react";
import SenhaModal from "../../components/modals/ConfirmaSenha";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import {
  TextField,
  Box,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";

import { FormGroup, Label } from "reactstrap";

import axios from "axios";

import { StyleSheet, css } from "aphrodite";
import "../../assets/styles/text.css";
import "../../assets/styles/botoes.css";

import { headers } from "../../components/core/store/localStorage";
import errorHandler from "../../components/core/store/errorHandler";
const API_URL = localStorage.getItem("apiUrl");
const user_ID = localStorage.getItem("userId");

function AlterarSenha() {
  const pgTitle = "Alterar Senha";

  const [oldPassword, setOldPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [critica, setCritica] = useState(false);
  const [erro, setErro] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleShowAll = () => {
    setShowOldPassword(!showOldPassword);
    setShowNewPassword(!showNewPassword);
    setShowConfirmPassword(!showConfirmPassword);
  };

  function sendData() {
    if (
      oldPassword.length === 0 &&
      newPassword.length === 0 &&
      confirmPassword.length === 0
    ) {
      setErro("Todos os campos devem ser preenchidos.");
    } else if (
      (oldPassword.length === 0 && newPassword.length === 0) ||
      (newPassword.length === 0 && confirmPassword.length === 0) ||
      (oldPassword.length === 0 && confirmPassword.length === 0)
    ) {
      setErro("Todos os campos devem ser preenchidos.");
    } else if (oldPassword.length === 0) {
      setErro("A senha atual deve ser informada.");
    } else if (newPassword.length === 0) {
      setErro("Uma nova senha deve ser informada.");
    } else if (confirmPassword.length === 0) {
      setErro("Preencha o campo de confirmação.");
    } else if (oldPassword === newPassword) {
      setErro("A nova senha deve ser diferente da senha atual.");
    } else if (newPassword !== confirmPassword) {
      setErro("A senha de confirmação deve ser igual a nova senha.");
    } else {
      axios
        .post(
          `${API_URL}/app/user/${user_ID}/replacepassword`,
          {
            currentPassword: oldPassword,
            newPassword: newPassword,
          },
          headers()
        )
        .then((response) => {
          console.log(response);
          setModalOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setErro("Senha atual incorreta. Preencha o campo novamente.");
        });
    }
  }

  const retorna = () => {
    setModalOpen(!modalOpen);
    window.location.reload();
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container wrap="wrap">
          <Grid
            item
            xs={6}
            sm={7}
            md={8}
            lg={10}
            style={{ marginTop: "-1.6rem" }}
            spacing={2}
          >
            <h3 className={css(styles.pageTitle)}>{pgTitle}</h3>
          </Grid>
        </Grid>

        <hr
          style={{
            color: "#eee",
            marginTop: "-0.55rem",
            marginBottom: "1rem",
          }}
        />
      </Box>

      <Grid
        container
        columns={{ md: 12 }}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        wrap="wrap"
      >
        <Grid item xs={12}>
          <div style={{ display: "flex" }}>
            <Grid item xs={4} style={{ marginRight: "1rem" }}>
              <FormGroup>
                <Label className={css(styles.label)} for="empresa">
                  Senha atual
                </Label>
                <TextField
                  fullWidth
                  error={critica && oldPassword.length === 0}
                  value={oldPassword}
                  type={showOldPassword ? "text" : "password"}
                  onChange={(ev) => setOldPassword(ev.target.value)}
                  size={"small"}
                  color={"primary"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowAll}
                          edge="end"
                        >
                          {showOldPassword ? (
                            <VisibilityOffIcon style={{ fontSize: 5 }} />
                          ) : (
                            <VisibilityIcon style={{ fontSize: 5 }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    marginTop: "0.5rem",
                    "& .MuiInputBase-root": {
                      height: 32,
                      // fontFamily: "Poppins",
                      fontSize: 12,
                      transition: "0.4s",
                      "&:hover fieldset": {
                        borderColor: "#ABABAB",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976D2 !important",
                      },
                    },
                  }}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={4} style={{ marginRight: "1rem" }}>
              <FormGroup>
                <Label className={css(styles.label)} for="empresa">
                  Nova senha
                </Label>
                <TextField
                  fullWidth
                  error={critica && newPassword.length === 0}
                  value={newPassword}
                  type={showNewPassword ? "text" : "password"}
                  onChange={(ev) => setNewPassword(ev.target.value)}
                  size={"small"}
                  color={"primary"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowAll}
                          edge="end"
                        >
                          {showNewPassword ? (
                            <VisibilityOffIcon style={{ fontSize: 5 }} />
                          ) : (
                            <VisibilityIcon style={{ fontSize: 5 }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    marginTop: "0.5rem",
                    "& .MuiInputBase-root": {
                      height: 32,
                      // fontFamily: "Poppins",
                      fontSize: 12,
                      transition: "0.4s",
                      "&:hover fieldset": {
                        borderColor: "#ABABAB",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976D2 !important",
                      },
                    },
                  }}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={4}>
              <FormGroup>
                <Label className={css(styles.label)} for="empresa">
                  Confirmar senha
                </Label>
                <TextField
                  fullWidth
                  error={critica && confirmPassword.length === 0}
                  value={confirmPassword}
                  type={showConfirmPassword ? "text" : "password"}
                  onChange={(ev) => setConfirmPassword(ev.target.value)}
                  size={"small"}
                  color={"primary"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowAll}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOffIcon style={{ fontSize: 5 }} />
                          ) : (
                            <VisibilityIcon style={{ fontSize: 5 }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    marginTop: "0.5rem",
                    "& .MuiInputBase-root": {
                      height: 32,
                      // fontFamily: "Poppins",
                      fontSize: 12,
                      transition: "0.4s",
                      "&:hover fieldset": {
                        borderColor: "#ABABAB",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976D2 !important",
                      },
                    },
                  }}
                />
              </FormGroup>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <p className={css(styles.erro)}>{erro}</p>

      <div
        style={{
          display: "flex",
          marginTop: "2rem",
          padding: "1rem 0",
          borderTop: "solid 0.5px #eee",
        }}
      >
        <button
          onClick={sendData}
          className="btn-sm btn-ativa"
          style={{ fontSize: 12, marginLeft: 5, padding: "6px 10px" }}
        >
          Confirmar
        </button>
      </div>

      <SenhaModal modalOpen={modalOpen} toggle={retorna} />
    </>
  );
}

const styles = StyleSheet.create({
  pageTitle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    color: "#4b4b4b",
  },
  label: {
    // fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 600,
    textAlign: "left",
  },
  input: {
    marginBottom: "0.2rem",
    marginBottom: "1rem",
    padding: 8,
    // fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    width: "100%",
    borderRadius: 4,
    borderWidth: 0.5,
    borderStyle: "solid",
    borderColor: "#CCCCCC",
    transition: "0.4s",

    ":hover": {
      transition: "0.4s",
      borderColor: "#ABABAB",
    },
    ":focus": {
      transition: "0.4s",
      outlineColor: "#1976D2",
      boxShadow: "none",
    },
  },
  erro: {
    // fontFamily: "Poppins",
    fontSize: 14,
    color: "red",
    textAlign: "center",
    fontWeight: 600,
    marginTop: "1.2rem",
    marginBottom: "-1.5rem",
  },
});

export default AlterarSenha;
