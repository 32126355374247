import Select from "react-select";

import { Box, Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import { StyleSheet, css } from "aphrodite";

import "../../assets/styles/muiComponents.css";
import { red } from "@mui/material/colors";

function Filter(props) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        wrap="wrap"
        spacing={1}
        style={{
          marginBottom: "1rem",
          paddingBottom: "1.5rem",
          borderBottom: "solid 0.5px #eee",
        }}
      >
        <Grid item xs={5} sm={4} md={3}>
          <DatePicker
            label="Início"
            sx={{
              width: "100%",
              marginTop: "0.5rem",
              "& .MuiInputBase-root": {
                color: "#4b4b4b",
                height: "2.35rem",
                // fontFamily: "Poppins",
                fontSize: 10,
              },
              "& .MuiFormLabel-root": {
                color: "#cacaca",
                // marginTop: -0.2,
                // fontFamily: "Poppins",
                fontSize: 12,
                zIndex: 0,
              },
            }}
            slotProps={{ textField: { size: "small" } }}
            value={props.iniValue}
            onChange={(ev) => props.iniSet(ev)}
          />
        </Grid>
        <Grid item xs={5} sm={4} md={3}>
          <DatePicker
            label="Fim"
            sx={{
              width: "100%",
              marginTop: "0.5rem",
              "& .MuiInputBase-root": {
                color: "#4b4b4b",
                height: "2.35rem",
                // fontFamily: "Poppins",
                fontSize: 10,
              },
              "& .MuiFormLabel-root": {
                color: "#cacaca",
                // marginTop: -0.2,
                // fontFamily: "Poppins",
                fontSize: 12,
                zIndex: 0,
              },
            }}
            slotProps={{ textField: { size: "small" } }}
            value={props.fimValue}
            onChange={(ev) => props.fimSet(ev)}
          ></DatePicker>
        </Grid>

        <Grid item xs={2} sm={1} md={0.6} lg={0.5}>
          <button
            onClick={() => props.search()}
            className={
              props.loading ? css(styles.btnDisabled) : css(styles.btn)
            }
            title="Buscar"
          >
            <SearchOutlinedIcon />
          </button>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p className={css(styles.erro)}>{props.erro}</p>
        </Grid>
      </Grid>
    </Box>
  );
}

const styles = StyleSheet.create({
  input: {
    fontFamily: "Poppins !important",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    transitionDuration: "0.2s",
  },
  btn: {
    backgroundColor: "#6163B0",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#6163B0",
    borderRadius: 6,

    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.4rem 0.3rem 0.4rem 0.4rem",
    width: "100%",
    marginTop: "0.55rem",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#6163B0",
      backgroundColor: "transparent !important",
      borderColor: "#6163B0 !important",
    },
    ":active": {
      color: "#6163B0",
      backgroundColor: "transparent !important",
      borderColor: "#6163B0 !important",
      opacity: 0.6,
    },
  },
  btnDisabled: {
    backgroundColor: "#B3B3B3",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#B3B3B3",
    borderRadius: 6,

    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.4rem 0.3rem 0.4rem 0.4rem",
    width: "100%",
    marginTop: "0.55rem",
  },
  erro: {
    // fontFamily: "Poppins",
    fontSize: 14,
    color: "red",
    textAlign: "center",
    fontWeight: 600,
    marginTop: "1rem",
    marginBottom: "-0.5rem",
  },
});

export default Filter;
