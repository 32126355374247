import { StyleSheet, css } from "aphrodite";
import { Dialog, Button, DialogTitle } from "@mui/material";

function SenhaModal(props) {
  return (
    <Dialog
      open={props.modalOpen}
      onClose={props.toggle}
      PaperProps={{
        sx: {
          width: "30%",
          maxHeight: 300,
        },
      }}
    >
      <DialogTitle className={css(styles.title)}>
        Alteração concluída
      </DialogTitle>
      <div>
        <p className={css(styles.text)}>Nova senha registrada com sucesso.</p>
      </div>
      <div className={css(styles.buttonContainer)}>
        <Button variant="outlined" color="info" onClick={props.toggle}>
          Voltar
        </Button>
      </div>
    </Dialog>
  );
}

export default SenhaModal;

const styles = StyleSheet.create({
  title: {
    // fontFamily: "Poppins",
    textAlign: "center",
    fontWeight: 700,
    marginBottom: -5,
  },
  text: {
    // fontFamily: "Poppins",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "3%",
  },
});
