import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import BotaoVoltar from "../../components/buttons/btnVoltar";
import ConfirmaUser from "../../components/modals/ConfirmaUser";

import { Box, Grid } from "@mui/material";

import { FormGroup, Input, Label } from "reactstrap";

import axios from "axios";
import { Link } from "react-router-dom";

import { StyleSheet, css } from "aphrodite";
import "../../assets/styles/text.css";
import "../../assets/styles/botoes.css";

import { headers } from "../../components/core/store/localStorage";
import errorHandler from "../../components/core/store/errorHandler";
const API_URL = localStorage.getItem("apiUrl");
const user_ID = localStorage.getItem("userId");

function NovoUsuario() {
  const navigate = useNavigate();

  const pgTitle = "Novo Usuário";

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [erro, setErro] = useState("");

  const [modalOpen, setModalOpen] = useState(false);

  function criaUser() {
    const info = {
      name: nome,
      email: email,
      roles: [user_ID],
      roleId: user_ID,
    };

    console.log(info);

    if (nome.length === 0 && email.length === 0) {
      setErro("Todos os campos devem ser preenchidos.");
    } else if (nome.length === 0) {
      setErro("O nome do usuário deve ser informado.");
    } else if (email.length === 0) {
      setErro("O e-mail do usuário deve ser informado.");
    } else {
      setErro("");
      axios.post(`${API_URL}/app/user/`, info, headers()).catch((err) => {
        console.log(err);
      });
      setModalOpen(!modalOpen);
    }
  }

  function voltar() {
    navigate("/lista-usuarios");
    window.location.reload();
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container wrap="wrap">
          <Grid
            item
            xs={6}
            sm={7}
            md={8}
            lg={10}
            style={{ marginTop: "-1.6rem" }}
          >
            <h3 className={css(styles.pageTitle)}>{pgTitle}</h3>
          </Grid>
        </Grid>

        <hr
          style={{
            color: "#eee",
            marginTop: "-0.55rem",
            marginBottom: "1rem",
          }}
        />
      </Box>

      <Grid container wrap="wrap" spacing={2}>
        <Grid item xs={12} md={3}>
          <FormGroup>
            <Label className={css(styles.label)} for="empresa">
              Nome
            </Label>
            <Input
              type="text"
              value={nome}
              onChange={(ev) => setNome(ev.target.value)}
              name="id"
              id="id"
              className={css(styles.input)}
              style={{ marginTop: "0.5rem" }}
            ></Input>
          </FormGroup>
        </Grid>

        <Grid item xs={12} md={5}>
          <FormGroup>
            <Label className={css(styles.label)} for="empresa">
              E-mail
            </Label>
            <Input
              type="text"
              value={email}
              onChange={(ev) => setEmail(ev.target.value)}
              name="id"
              id="id"
              className={css(styles.input)}
              style={{ marginTop: "0.5rem" }}
            ></Input>
          </FormGroup>
        </Grid>
      </Grid>

      <p className={css(styles.erro)}>{erro}</p>

      <div
        style={{
          display: "flex",
          marginTop: "1rem",
          padding: "1rem 0",
          borderTop: "solid 0.5px #eee",
        }}
      >
        <Link to={"/lista-usuarios"} style={{ textDecoration: "none" }}>
          <BotaoVoltar />
        </Link>

        <button
          onClick={criaUser}
          className="btn-sm btn-ativa"
          style={{ fontSize: 12, marginLeft: 5 }}
        >
          Criar Usuário
        </button>
      </div>

      <ConfirmaUser modalOpen={modalOpen} toggle={voltar} />
    </>
  );
}

const styles = StyleSheet.create({
  pageTitle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    color: "#4b4b4b",
  },
  label: {
    // fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 600,
    textAlign: "left",
  },
  input: {
    marginBottom: "0.2rem",
    marginBottom: "1rem",
    padding: 8,
    // fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    width: "100%",
    borderRadius: 4,
    borderWidth: 0.5,
    borderStyle: "solid",
    borderColor: "#CCCCCC",
    transition: "0.4s",

    ":hover": {
      transition: "0.4s",
      borderColor: "#ABABAB",
    },
    ":focus": {
      transition: "0.4s",
      outlineColor: "#1976D2",
      boxShadow: "none",
    },
  },
  erro: {
    // fontFamily: "Poppins",
    fontSize: 14,
    color: "red",
    textAlign: "center",
    fontWeight: 600,
    marginTop: "0.2rem",
    marginBottom: "-0.5rem",
  },
});

export default NovoUsuario;
